<template>
    <div class="expanded-viewport overflow-hidden">
        <AppbarFixed :hasBackButton="true"/>
        <div class="mt-5">
            <pdf :src="url"></pdf>
        </div>
    </div>
</template>

<script>
import AppbarFixed from '../../components/general/AppbarFixed.vue';
import pdf from 'vue-pdf';

export default {
    components: {
        AppbarFixed,
        pdf,
    },
    computed: {
        url() {
            return this.$route.params.url ?? "#";
            // return "http://www.africau.edu/images/default/sample.pdf";
        }
    },
    mounted() {
        window.scrollTo(0,0);
    }
}
</script>

<style lang="scss" scoped>

</style>